/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "src/assets/mixins";
@import "src/assets/variables";

.col-xs-1{
    width:calc(100% / 12); //8.33%
}
.col-xs-2{
    width:calc(2*(100% / 12)); //16.6%
}
.col-xs-3{
    width:calc(3*(100% / 12)); //25%;
}
.col-xs-4{
    width:calc(4*(100% / 12)); //33.3%
}
.col-xs-6{
    width:calc(6*(100% / 12)); //50%
}
.col-xs-8{
    width:calc(8*(100% / 12)); //66.6%
}
.col-xs-10{
    width:calc(10*(100% / 12)); //83.3%
}
.col-xs-12{
    width:100%; 
}
*{
    box-sizing: border-box;
}

body,h1,h2,h3,h4,h5,h6,ul,li,p{
    margin: 0;
    padding: 0;
    font-size: inherit;
}
html, body{
    height: 100% ;
}




.show{
    display: inline-block !important;
}

.hide{
    display: none !important;
    opacity: 1 !important;
}

.opacity-none{
    opacity: 0 !important;
}

.center-position{
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

.floatedLeft{
    float: left;
}

.cdk-overlay-pane{
    max-width: 100vw !important;
}

.cdk-overlay-full-width-class{
    max-width: 100vw !important;
}

ls-suggestion .cdk-overlay-container:empty{
    display: unset !important;
}