@mixin container($height, $width){
    height: $height;
    width: $width;
}

@mixin bgContainer($height, $width, $background-color){
    @include container($height, $width);
    background-color: $background-color;
}

@mixin padding($top, $right, $bottom, $left){
    padding: $top $right $bottom $left;
}

@mixin margin($top, $right, $bottom, $left){
    margin: $top $right $bottom $left;
}

@mixin font-style($font-family, $font-size, $font-color, $font-weight){
    font-family: $font-family;
    font-size: $font-size;
    color: $font-color;
    font-weight: $font-weight;
}

@mixin center-position($top, $bottom, $left, $right, $position,$margin){
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
    position: $position;
    margin: $margin;
}

@mixin background-position($background-position, $background-repeat){
    background-position: $background-position;
    background-repeat: $background-repeat;
}
@mixin font($font-family){
    font-family: $font-family, Arial, Helvetica, sans-serif, Arial, Helvetica, sans-serif;
}